import { inject, Injectable } from '@angular/core';
import { v1 as uuid } from 'uuid';
import { UserStore } from '../stores/user.store'; // eslint-disable-next-line @typescript-eslint/no-explicit-any

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let fbq: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// declare let ttq: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// declare let twq: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly userStore = inject(UserStore);

  constructor() {}

  track(action: string): void {
    const eventId = `${action}-${Date.now()}-${uuid()}`;

    fbq('track', action, {}, { eventID: eventId });

    // ttq.identify({
    //   email: this.userStore.user()?.email,
    //   external_id: this.userStore.user()?.id,
    // });
    // ttq.track(action, {
    //   event_id: eventId,
    // });
  }

  addToWishlist(): void {
    this.track('AddToWishlist');
  }

  completeRegistration(): void {
    this.track('CompleteRegistration');
  }

  pageView(): void {
    this.track('PageView');
  }

  lead(): void {
    this.track('Lead');

    // ttq.identify({
    //   email: this.userStore.user()?.email,
    //   external_id: this.userStore.user()?.id,
    // });
    // ttq.track('Lead', {
    //   event_id: eventId,
    // });
  }
}
